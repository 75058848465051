<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="title"
    :visible.sync="visible"
    center
    width="300px"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="auto">
      <!--姓名-->
      <el-form-item :label="$t('title.username')" prop="agent">
        <el-select
          v-model="form.agent"
          :disabled="!isAdd"
          :placeholder="$t('page.selectPlaceholder')"
          clearable
          filterable
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.userName"
            :value="item.userName"
          />
        </el-select>
      </el-form-item>
      <!--小组-->
      <el-form-item :label="$t('title.Group')" prop="group">
        <el-select
          v-model="form.group"
          :placeholder="$t('page.selectPlaceholder')"
          clearable
          filterable
        >
          <el-option
            v-for="item in teamList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <!--角色-->
      <el-form-item :label="$t('page.Character')" prop="role">
        <el-select
          v-model="form.role"
          :placeholder="$t('page.selectPlaceholder')"
          clearable
          filterable
        >
          <el-option
            v-for="item in roleList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="handleClose">{{ $t('order.Cancel') }}</el-button>
      <el-button type="primary" @click="handleSubmit">{{ $t('page.sure') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { roleList, teamList } from '../const'
import { addQualityAgent, updateQualityAgent } from './apis/addDialog'

export default {
  name: 'AddDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    row: {
      type: Object,
      default: null
    },
    userList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        agent: '',
        group: '',
        role: ''
      },
      rules: {
        agent: [
          { required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' }
        ],
        group: [
          { required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' }
        ],
        role: [
          { required: true, message: this.$t('page.selectPlaceholder'), trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    isAdd() {
      return this.type === 'add'
    },
    title() {
      return this.isAdd ? this.$t('page.add') : this.$t('page.modify')
    },
    submitFn() {
      return this.isAdd ? addQualityAgent : updateQualityAgent
    },
    teamList() {
      return teamList
    },
    roleList() {
      return roleList
    }
  },
  watch: {
    visible(val) {
      if (val && !this.isAdd) {
        this.form = {
          ...this.row
        }
      }
    }
  },
  methods: {
    handleClose() {
      this.form = {
        agent: '',
        group: '',
        role: ''
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      this.$emit('update:row', null)
      this.$emit('update:visible', false)
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$confirm(this.$t('title.batchprocessing'), this.$t('page.Prompt'), {
            closeOnClickModal: false,
            type: 'warning',
            beforeClose: async(action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = instance.cancelButtonLoading = true
                const { msg } = await this.submitFn(this.form).finally(() => {
                  instance.confirmButtonLoading = instance.cancelButtonLoading = false
                })
                this.$message.success(msg)
                this.$emit('refresh')
                this.handleClose()
                done()
              } else {
                done()
              }
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
