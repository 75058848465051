import request from '@/utils/request'

export function addQualityAgent(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/insertQualityAgent`,
    method: 'post',
    data
  })
}

export function updateQualityAgent(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/updateQualityAgent`,
    method: 'post',
    data
  })
}
