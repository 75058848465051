<template>
  <div>
    <el-form inline size="small">
      <!--姓名-->
      <el-form-item :label="$t('title.username')">
        <el-select
          v-model="form.agent"
          :placeholder="$t('page.selectPlaceholder')"
          clearable
          filterable
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.userName"
            :value="item.userName"
          />
        </el-select>
      </el-form-item>
      <!--小组-->
      <el-form-item :label="$t('title.Group')">
        <el-select
          v-model="form.group"
          :placeholder="$t('page.selectPlaceholder')"
          clearable
          filterable
        >
          <el-option
            v-for="item in teamList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <!--角色-->
      <el-form-item :label="$t('page.Character')">
        <el-select
          v-model="form.role"
          :placeholder="$t('page.selectPlaceholder')"
          clearable
          filterable
        >
          <el-option
            v-for="item in roleList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-button :loading="queryLoading" type="primary" @click="handleQuery">
          {{ $t('page.search') }}
        </el-button>
        <el-button @click="handleReset">{{ $t('page.reset') }}</el-button>
      </el-form-item>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <el-button size="small" type="primary" @click="handleOpenAddDialog('add', null)">
          {{ $t('page.add') }}
        </el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :row-class-name="({row}) => +row.delFlag === +statusEnum['停用'] && 'row-disabled'"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <!--姓名-->
      <vxe-column :title="$t('title.username')" field="agent" />
      <!--小组-->
      <vxe-column :title="$t('title.Group')" field="group" />
      <!--角色-->
      <vxe-column :title="$t('page.Character')" field="role" />
      <vxe-column title="是否停用">
        <template #default="{row}">
          <span v-if="+row.delFlag === +statusEnum['停用']">{{ statusMap[row.delFlag] }}</span>
          <span v-else>/</span>
        </template>
      </vxe-column>
      <vxe-column :title="$t('page.operate')">
        <template #default="{row}">
          <!--启用-->
          <el-button
            v-if="+row.delFlag === +statusEnum['停用']"
            type="text"
            @click="handleEditStatus(row, statusEnum['启用'])"
          >
            {{ $t('page.enabled') }}
          </el-button>
          <el-button
            v-if="+row.delFlag === +statusEnum['启用']"
            type="text"
            @click="handleEditStatus(row, statusEnum['停用'])"
          >
            {{ $t('page.disable') }}
          </el-button>
          <el-button type="text" @click="handleOpenAddDialog('edit', row)">
            {{ $t('page.modify') }}
          </el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <paging ref="pager" :pager="pager" end @update="handlePagerUpdate" />
    <add-dialog
      :row.sync="currentRow"
      :type="type"
      :user-list="userList"
      :visible.sync="addDialogVisible"
      @refresh="handleQuery"
    />
  </div>
</template>

<script>
import { debounceGetTableMaxHeight } from '@/utils'
import Paging from '@/components/Paging/index.vue'
import AddDialog from './AddDialog.vue'
import { roleList, teamList } from '../const'
import { getQualityAgentList } from './apis/list'
import { statusEnum, statusMap } from './const'
import { updateQualityAgent } from './apis/addDialog'
import { getBuPage } from '@/api/service-management'

export default {
  name: 'CustomerServiceList',
  components: {
    Paging,
    AddDialog
  },
  data() {
    return {
      userList: [],
      form: {
        agent: '',
        group: '',
        role: ''
      },
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      queryLoading: false,
      maxHeight: 0,
      tableData: [],
      addDialogVisible: false,
      currentRow: null,
      type: ''
    }
  },
  computed: {
    queryParams() {
      return {
        ...this.form,
        ...this.pager
      }
    },
    statusMap() {
      return statusMap
    },
    statusEnum() {
      return statusEnum
    },
    teamList() {
      return teamList
    },
    roleList() {
      return roleList
    }
  },
  watch: {
    form: {
      handler() {
        this.queryChange = true
      },
      deep: true
    }
  },
  created() {
    this.getAllUserList()
    this.handleQuery()
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  mounted() {
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    async getAllUserList() {
      const { datas } = await getBuPage({
        current: 1,
        size: 1000
      })
      this.userList = datas?.records || []
    },
    async handleQuery() {
      this.queryLoading = true
      if (this.controller) {
        this.controller.abort()
        setTimeout(() => {
          this.queryLoading = true
        })
      }
      this.controller = new AbortController()
      const signal = this.controller.signal
      if (this.queryChange) {
        this.pager.current = 1
      }
      const { datas } = await getQualityAgentList(this.queryParams, signal).finally(() => {
        this.queryLoading = this.queryChange = false
      })
      this.tableData = datas?.records || []
      this.pager.total = datas?.pager?.total || 0
    },
    handleReset() {
      this.form = {
        agent: '',
        group: '',
        role: ''
      }
      this.handleQuery()
    },
    handleOpenAddDialog(type, row) {
      this.type = type
      this.currentRow = row
      this.addDialogVisible = true
    },
    handleEditStatus(row, status) {
      this.$confirm(this.$t('title.batchprocessing'), this.$t('page.Prompt'), {
        closeOnClickModal: false,
        type: 'warning',
        beforeClose: async(action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = instance.cancelButtonLoading = true
            const { msg } = await updateQualityAgent({
              ...row,
              delFlag: status
            }).finally(() => {
              instance.confirmButtonLoading = instance.cancelButtonLoading = false
            })
            this.$message.success(msg)
            done()
            await this.handleQuery()
          } else {
            done()
          }
        }
      })
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss">
.row-disabled {
  color: #C0C4CC;
}
</style>
