import { generateListByMap } from '@/utils'

export const teamList = [
  {
    label: 'Official Website',
    value: 'Official Website'
  },
  {
    label: 'Amazon&other platforms',
    value: 'Amazon&other platforms'
  }
]

export const roleMap = {
  TeamLeader: 'TeamLeader',
  '质检员': '质检员',
  '客服': '客服'
}

export const { list: roleList, enum: roleEnum } = generateListByMap(roleMap)

export const scoreTypeMap = {
  1: 'Problem',
  2: 'Suggestion',
  3: 'Excellent'
}

export const { enum: scoreTypeEnum } = generateListByMap(scoreTypeMap)

export const scoreNatureMap = {
  1: 'Excellent',
  2: 'Qualified',
  3: 'Unqualified'
}

export const { list: scoreNatureList, enum: scoreNatureEnum } = generateListByMap(scoreNatureMap)
